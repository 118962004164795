<div class="headers ">
  <div class="title">User Leave</div>
  <button class="closebtn" (click)="closeModel()"><svg class="" color="#FFFFFF" width="10" height="10" viewBox="0 0 10 10"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78571 8.78572C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78572 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78572L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78572 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78572Z"
        fill="white" />
    </svg>
  </button>
</div>
<div class="models">
  <div class="text-1xl font-bold">{{data?.selectedUser?.firstName}} {{data?.selectedUser?.lastName}} ( {{data?.selectedUser?.email}})</div>
  <div class="flex items-center mt-2">
    <div class="font-medium text-secondary">Available Leaves</div>
    <div class="flex-auto ml-2 border-b-2"></div>
</div>
<div class="grid grid-cols-1 lg:grid-cols-2 gap-y-4 mt-2">

    <div class="flex items-center">
        <div class="ml-2">Vacation + Additional: 
          <span class="text-1xl font-bold">{{(leaveDetails?.actualVacation + leaveDetails?.additionalVacation?.additionalVacation) - leaveDetails?.totalVacationLeavesConsumed}}</span>
        </div>
    </div>
    <div class="flex items-center">
      <div class="ml-2">Additional Info: 
    <span class="text-1xl font-bold">
      {{leaveDetails?.additionalVacation?.additionalVacation}}
      <span  *ngIf="leaveDetails?.additionalVacation?.expiryType == 1">( Expire On: {{leaveDetails?.additionalVacation?.expiryDate | date}})</span>
    </span>
  </div>
</div>
</div>
<div class="flex items-center mt-2">
  <div class="font-medium text-secondary">Taken Leaves</div>
  <div class="flex-auto ml-2 border-b-2"></div>
</div>
<div class="grid grid-cols-1 lg:grid-cols-3 gap-y-4 mt-2">
  <div class="flex items-center">
    <div class="ml-2">Vacation: <span class="text-1xl font-bold">{{leaveDetails?.totalVacationLeavesConsumed}}</span></div>
  </div>
    <div class="flex items-center">
      <div class="ml-2">Operational Relocation: <span class="text-1xl font-bold">{{leaveDetails?.operationalRelocation}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Compassionate Leave : <span class="text-1xl font-bold">{{leaveDetails?.compassionateLeave}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Child bearing : <span class="text-1xl font-bold">{{leaveDetails?.childbearing}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Wedding Leave : <span class="text-1xl font-bold">{{leaveDetails?.weddingLeave}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Business Trip : <span class="text-1xl font-bold">{{leaveDetails?.businessTrip}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Child Sick : <span class="text-1xl font-bold">{{leaveDetails?.childSick}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Compensation : <span class="text-1xl font-bold">{{leaveDetails?.compensation}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Paternal : <span class="text-1xl font-bold">{{leaveDetails?.paternal}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Sick Leave : <span class="text-1xl font-bold">{{leaveDetails?.sickLeave}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Special Leave : <span class="text-1xl font-bold">{{leaveDetails?.specialLeave}}</span></div>
  </div>
  <div class="flex items-center">
    <div class="ml-2">Unpaid Leave : <span class="text-1xl font-bold">{{leaveDetails?.unpaidLeave}}</span></div>
  </div>

</div>
  <hr>
  <form class="rounded ng-untouched ng-pristine ng-invalid" [formGroup]="absenceCreationFrom" (ngSubmit)="onSubmit()">
    <div class="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
      <mat-form-field class="form-field">
        <mat-label>Absence Type</mat-label>
        <mat-select [formControlName]="'titleId'" required>
          <mat-option>
            <ngx-mat-select-search ngModel [formControlName]="'titleSearch'" [placeholderLabel]="'Search Absence Type'" (ngModelChange)="filtersItem($event)"></ngx-mat-select-search>
          </mat-option>
          <mat-option [value]="''">Select Absence Type</mat-option>
          <mat-option *ngFor="let record of leaveTypes" [value]="record._id">{{ record.name |titlecase}}</mat-option>
        </mat-select>
        <mat-error [control]="absenceCreationFrom.get('titleId')"></mat-error>
      </mat-form-field>

      <div class="form-field">
        <mat-label>Leave Type</mat-label>
        <mat-checkbox  formControlName="allDay">
          All Day
        </mat-checkbox>
        <mat-error  [control]="absenceCreationFrom.get('allDay')"></mat-error>
      </div>

      <mat-form-field class="form-field">
        <mat-label>Start Date</mat-label>
        <input matInput  [readonly]="true"   [ngxMatDatetimePicker]="startDatePicker" placeholder="Choose a date" [formControl]="startDate"
          [min]="minDate" (ngModelChange)="onChangeStartDate($event)">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #startDatePicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [hideTime]="hideTime" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field class="form-field">
        <mat-label>End Date</mat-label>
        <input matInput [readonly]="true" (ngModelChange)="onChangeEndDate($event)"    [ngxMatDatetimePicker]="endDatePicker" placeholder="Choose a date" [formControl]="endDate"
          [min]="minEndDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #endDatePicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [hideTime]="hideTime" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
          [color]="color" [enableMeridian]="enableMeridian">
        </ngx-mat-datetime-picker>
      </mat-form-field>

      <mat-form-field class="form-field" *ngIf="data?.isManager">
        <mat-label>Absence Status</mat-label>
        <mat-select [formControlName]="'absenceStatus'" required>
          <mat-option [value]="''">Select Absence Status</mat-option>
          <mat-option *ngFor="let record of absenceStatusList" [value]="record.status">{{ record.display | titlecase}}</mat-option>
        </mat-select>
        <mat-error [control]="absenceCreationFrom.get('absenceStatus')"></mat-error>
      </mat-form-field>
    </div>
    <div class="grid lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1">
      <mat-form-field class="textarea">
        <mat-label>Reason</mat-label>
        <textarea class="txt" matInput [formControlName]="'description'" rows="2"
          placeholder="reason"></textarea>
        <mat-error [control]="absenceCreationFrom.get('description')"></mat-error>
      </mat-form-field>
    </div>
    <div  class="flex justify-end mt-10">
      <button
      *ngIf="!data?.selectInfo?.id"
        mat-flat-button
        [color]="'primary'"
        class="mr-2"
        type="submit">
        <span>Create Request</span>
      </button>
    </div>
  </form>
</div>
