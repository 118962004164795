<div class="headers ">
  <div class="title flex">
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="10.5" cy="10" rx="10.5" ry="10" fill="white" />
      <g clip-path="url(#clip0_418_5595)">
        <path
          d="M10.4977 13.5C12.3092 13.5 13.7778 11.933 13.7778 10C13.7778 8.067 12.3092 6.5 10.4977 6.5C8.6862 6.5 7.21767 8.067 7.21767 10C7.21767 11.933 8.6862 13.5 10.4977 13.5ZM10.4977 12.5C9.20351 12.5 8.15483 11.381 8.15483 10C8.15483 8.619 9.20351 7.5 10.4977 7.5C11.7915 7.5 12.8406 8.619 12.8406 10C12.8406 11.381 11.7915 12.5 10.4977 12.5ZM10.4977 6C11.9433 6 13.4802 6.3485 14.9961 7.786C15.5457 8.3075 16.056 8.8725 16.5147 9.444C16.6094 9.562 16.7322 9.7225 16.8221 9.8405C16.8933 9.934 16.8952 10.0655 16.8245 10.159C16.7537 10.2525 16.6506 10.386 16.5147 10.5555C16.056 11.127 15.5457 11.692 14.9961 12.2135C13.4802 13.651 11.9433 13.9995 10.4977 13.9995C9.05216 13.9995 7.51522 13.651 5.99937 12.2135C5.44973 11.692 4.93945 11.1275 4.48071 10.5555C4.43151 10.494 4.37434 10.421 4.31811 10.3485C4.16067 10.1445 4.15926 9.853 4.31717 9.65C4.36497 9.5885 4.41932 9.52 4.48024 9.444C4.93898 8.8725 5.44926 8.3075 5.9989 7.786C7.51522 6.3485 9.05216 6 10.4977 6ZM10.4977 5C8.7757 5 7.04805 5.454 5.37663 7.0385C4.79278 7.592 4.25345 8.1895 3.76847 8.7935C3.46249 9.1745 3.23148 9.491 3.08856 9.703L3 9.834V9.9955V10.0045V10.166L3.08856 10.297C3.23148 10.509 3.46249 10.825 3.76847 11.2065C4.25345 11.811 4.79278 12.408 5.37663 12.9615C7.04805 14.5465 8.77523 15 10.4977 15C12.2197 15 13.9474 14.5465 15.6188 12.9615C16.2027 12.4075 16.742 11.8105 17.227 11.2065C17.533 10.8255 17.764 10.509 17.9069 10.297L17.9954 10.166V10.0045V9.9955V9.834L17.9069 9.703C17.764 9.4915 17.533 9.175 17.227 8.7935C16.742 8.189 16.2031 7.592 15.6188 7.0385C13.9474 5.454 12.2197 5 10.4977 5Z"
          fill="#202425"
        />
      </g>
      <defs>
        <clipPath id="clip0_418_5595">
          <rect width="15" height="10" fill="white" transform="translate(3 5)" />
        </clipPath>
      </defs>
    </svg>

    <span class="icon">User Profile</span>
  </div>

  <button class="closebtn" mat-dialog-close>
    <svg class="" color="#FFFFFF" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78571 8.78572C10.0714 9.07143 10.0714 9.5 9.78571 9.78571C9.64286 9.92857 9.5 10 9.28571 10C9.07143 10 8.92857 9.92857 8.78572 9.78571L5 6L1.21429 9.78571C1.07143 9.92857 0.928571 10 0.714286 10C0.5 10 0.357143 9.92857 0.214286 9.78571C-0.0714286 9.5 -0.0714286 9.07143 0.214286 8.78572L4 5L0.214286 1.21429C-0.0714286 0.928571 -0.0714286 0.5 0.214286 0.214286C0.5 -0.0714286 0.928571 -0.0714286 1.21429 0.214286L5 4L8.78572 0.214286C9.07143 -0.0714286 9.5 -0.0714286 9.78571 0.214286C10.0714 0.5 10.0714 0.928571 9.78571 1.21429L6 5L9.78571 8.78572Z"
        fill="white"
      />
    </svg>
  </button>
</div>

<div class="modelss">
  <!-- <div class="common" *ngIf="viewAccess('firstName,lastName,departmentId, supervisorId, userProfile,countOfTotalLeaves,deductCountOfLeaves, positionId')"> -->
  <div
    class="common"
    *ngIf="
      detailsFields?.firstName?.read ||
      detailsFields?.lastName?.read ||
      detailsFields?.departmentId?.read ||
      detailsFields?.supervisorId?.read ||
      detailsFields?.userProfile?.read ||
      detailsFields?.countOfTotalLeaves?.read ||
      detailsFields?.deductCountOfLeaves?.read ||
      detailsFields?.positionId?.read
    "
  >
    <div class="modules">
      <div class="heading-details pb-4">User Details</div>
      <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 ">
        <div class="pb-2" *ngIf="detailsFields?.firstName?.read">
          <span class="sub-heading">First Name</span><br />
          <span class="details">{{ record?.firstName }}</span>
        </div>
        <div class="pb-2" *ngIf="detailsFields?.lastName?.read">
          <span class="sub-heading">Last Name</span><br />
          <span class="details">{{ record?.lastName }}</span>
        </div>
        <div class="pb-2" *ngIf="detailsFields?.userProfile?.read">
          <span class="sub-heading">User Template Name</span><br />
          <span class="details">{{ record?.userProfile?.name }}</span>
        </div>
        <div class="pb-2" *ngIf="detailsFields?.positionId?.read">
          <span class="sub-heading">Position</span><br />
          <span class="details">{{ record?.position }}</span>
        </div>
        <div class="pb-2" *ngIf="detailsFields?.departmentId?.read">
          <span class="sub-heading">Department</span><br />
          <span class="details">{{ record?.department }}</span>
        </div>
        <div class="pb-2" *ngIf="detailsFields?.supervisorId?.read && record?.supervisor">
          <span class="sub-heading">Supervisor</span><br />
          <span class="details">{{ record?.supervisor }}</span>
        </div>

        <div class="pb-2" *ngIf="detailsFields?.countOfTotalLeaves?.read">
          <span class="sub-heading">Count Of Total Leaves(Vacation + Additional)</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.actualVacation + record?.remainingLeaveInformation?.additionalVacation?.additionalVacation}}</span>
        </div>
        <div>
          <span class="sub-heading">Additional Vacation</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.additionalVacation?.additionalVacation }} 
            <span *ngIf="record?.remainingLeaveInformation?.additionalVacation?.expiryType == 1">( Expire On: {{record?.remainingLeaveInformation?.additionalVacation?.expiryDate | date}})</span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="common" *ngIf="viewAccess('userLeaveInformation')">
    <div class="modules">
      <div class="heading-details pb-4">Available Leave Information</div>
      <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 ">
        <!-- <div>
          <span class="sub-heading">Child Sick</span><br />
          <span class="details">{{ record?.userLeaveInformation?.childSick }}</span>
        </div>
        <div>
          <span class="sub-heading">Child Bearing</span><br />
          <span class="details">{{ record?.userLeaveInformation?.childbearing }}</span>
        </div>
        <div>
          <span class="sub-heading">Compassionate Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.compassionateLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Compensation</span><br />
          <span class="details">{{ record?.userLeaveInformation?.compensation }}</span>
        </div>
        <div>
          <span class="sub-heading">Maternity Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.maternalLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Operational Relocation</span><br />
          <span class="details">{{ record?.userLeaveInformation?.operationalRelocation }}</span>
        </div>
        <div>
          <span class="sub-heading">Paternal Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.paternal }}</span>
        </div>
        <div>
          <span class="sub-heading">Sick Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.sickLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Special Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.specialLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Unpaid Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.unpaidLeave }}</span>
        </div> -->
        <div>
          <span class="sub-heading">Vacation</span><br />
          <span class="details">{{ ( record?.remainingLeaveInformation?.actualVacation + record?.remainingLeaveInformation?.additionalVacation?.additionalVacation) - record?.remainingLeaveInformation?.totalVacationLeavesConsumed}}</span>
        </div>
       
        <!-- <div>
          <span class="sub-heading">Wedding Leave</span><br />
          <span class="details">{{ record?.userLeaveInformation?.weddingLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Business Trip</span><br />
          <span class="details">{{ record?.userLeaveInformation?.businessTrip }}</span>
        </div> -->
      </div>
    </div>
  </div>
  <div class="common" *ngIf="viewAccess('remainingLeaveInformation')">
    <div class="modules">
      <div class="heading-details pb-4">Taken Leave</div>
      <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 ">
        <div>
          <span class="sub-heading">Child Sick</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.childSick }}</span>
        </div>
        <div>
          <span class="sub-heading">Child Bearing</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.childbearing }}</span>
        </div>
        <div>
          <span class="sub-heading">Compassionate Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.compassionateLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Compensation</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.compensation }}</span>
        </div>
        <div>
          <span class="sub-heading">Maternity Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.maternalLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Operational Relocation</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.operationalRelocation }}</span>
        </div>
        <div>
          <span class="sub-heading">Paternal</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.paternal }}</span>
        </div>
        <div>
          <span class="sub-heading">Sick Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.sickLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Special Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.specialLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Unpaid Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.unpaidLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Vacation</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.totalVacationLeavesConsumed }}</span>
        </div>
        <div>
          <span class="sub-heading">Wedding Leave</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.weddingLeave }}</span>
        </div>
        <div>
          <span class="sub-heading">Business Trip</span><br />
          <span class="details">{{ record?.remainingLeaveInformation?.businessTrip }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
